import React from "react"
import { Link, graphql } from "gatsby"

import DefaultLayout from "../layouts/default"
import SEO from "../components/seo"

const PostTemplate = ({ data, pageContext }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { next, prev } = pageContext
  return (
    <DefaultLayout>
      <SEO title={frontmatter.title} />
      <article>
        <div className="center">
          <h1 className="title">{frontmatter.title}</h1>
          <span className="code">
            <small>{frontmatter.date}</small>
          </span>
        </div>
        {pageContext.slug !== "/about/" && <div className="divider" />}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </article>
      {pageContext.slug !== "/about/" && (
        <div className="page-navigation code">
          {prev && (
            <Link
              className="prev"
              to={prev.fields.slug}
              title={prev.frontmatter.title}
            >
              &lt;&lt;
            </Link>
          )}
          <span> &middot; </span>
          <Link to="/blog" title="All posts">
            {" "}
            Back to all posts{" "}
          </Link>
          <span> &middot; </span>
          {next && (
            <Link
              className="next"
              to={next.fields.slug}
              title={next.frontmatter.title}
            >
              &gt;&gt;
            </Link>
          )}
        </div>
      )}
    </DefaultLayout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
